import Highway from '@dogstudio/highway';
import { Swiper, Navigation } from "swiper";
import WhereOnPage from "../../whereOnPage";
import Video from '../../video';
import Audio from '../../audio';
Swiper.use([Navigation]);

export default class DetailRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        const carousel = document.querySelector('.swiper-container');
        if (carousel) {
            var mySwiper = new Swiper('.swiper-container', {
                slidesPerView: 1,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2
                    },
                    1024: {
                        slidesPerView: 3
                    }
                }
            });
            document.querySelector('.swiper-container').style.overflow = 'visible';
        }
    }
    onLeave() {}
    onEnterCompleted() {
        new Video();
        new Audio();
        new WhereOnPage();
    }
    onLeaveCompleted() {
    }
}
