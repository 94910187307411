//Tailwind
import './scss/app.scss';

//Alpine JS
import 'alpine-magic-helpers/dist/component';
import Alpinejs from 'alpinejs'

//Admin
import AdminTabs from "./js/adminTabs";
import CookieAgreed from "./js/cookieAgreed";

//Plugins
import Highway from '@dogstudio/highway';
import LockScroll from "./js/lockScroll";
import Menu from "./js/menu";
import Video from "./js/video";
import 'pace-progressbar';
import ShowHideTopBar from "./js/showHideTopBar";
import Header from "./js/header";

//Renderers
import HomeRenderer from "./js/highway/renderer/home-renderer";
import AboutRenderer from "./js/highway/renderer/about-renderer";
import StoriesRenderer from "./js/highway/renderer/stories-renderer";
import InitiativesRenderer from "./js/highway/renderer/initiatives-renderer.js";
import DetailRenderer from "./js/highway/renderer/detail-renderer.js";

//Transitions
import Fade from "./js/highway/transition/fade";
import Custom from "./js/highway/transition/custom";

// Misc
import Alpine from './js/alpine';
import MicroModal from 'micromodal';


// Init plugins

window.lockScroll = new LockScroll();
window.menu = new Menu();
window.header = new Header();

// Highway config
const H = new Highway.Core({
    renderers: {
        homepage: HomeRenderer,
        about: AboutRenderer,
        stories: StoriesRenderer,
        initiatives: InitiativesRenderer,
        story: DetailRenderer,
        initiative: DetailRenderer,
        joinus: HomeRenderer,
        faq: HomeRenderer,
        default: HomeRenderer
    },
    transitions: {
        default: Custom
    }
});

// Get all menu links
const links = document.querySelectorAll('[role="navigation"] a');
checkActiveLink(links);

H.on('NAVIGATE_IN', ({ to, trigger, location }) => {

  // Where on page
  if(document.getElementById('whereonpage')) {
    document.getElementById('whereonpage').remove();
  }
  let whereInPage = to.page.getElementById('whereonpage');
  if(whereInPage) {
    document.getElementById('header').appendChild(whereInPage);
  }

  // Check Active Link
  checkActiveLink(links);

});

H.on('NAVIGATE_OUT', ({ to, trigger, location }) => {

  // Close the menu
  window.menu.close();

  if (document.getElementById('admin-tools')){
    window.location.reload();
  }
});

H.on('NAVIGATE_END', ({ to }) => {

  // Accessible page title replacement
  var pageTitle = document.querySelector('[data-pagetitle]')
  pageTitle.innerHTML = to.page.title;
  pageTitle.focus();

});

//Admin
new AdminTabs();

//GDPR
document.addEventListener('consent-given', function() {
    console.log('consent given');
    //Enable matomo or google analytics here ?
});
new CookieAgreed();

// Check active links
function checkActiveLink(links) {
  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    link.classList.remove('is-active');
    link.removeAttribute('aria-current');
    if (location.href.indexOf(link.href) > -1) {
      link.classList.add('is-active');
      link.setAttribute('aria-current', true);
    }
  }
}