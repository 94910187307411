import Cookies from 'js-cookie';

export default class AdminTabs {
  constructor() {
    if (!document.querySelector('.tabs .open')) return null;

    document.querySelector('.tabs .open').addEventListener('click', function(e){
      this.open();
    }.bind(this));
    document.querySelector('.tabs .close').addEventListener('click', function(e){
      this.close();
    }.bind(this));

    if (Cookies.get('admin-tabs') != 1){
      this.close();
    }else{
      this.open();
    }
  }

  open(){
    Cookies.set('admin-tabs', 1, {expires: 365} );
    document.querySelector('nav.tabs .tabs-content').classList.remove('hidden');
    document.querySelector('.tabs .close').classList.remove('hidden');
    document.querySelector('.tabs .open').classList.add('hidden');
  }

  close(){
    Cookies.set('admin-tabs', 0, {expires: 365} );
    document.querySelector('nav.tabs .tabs-content').classList.add('hidden');
    document.querySelector('.tabs .open').classList.remove('hidden');
    document.querySelector('.tabs .close').classList.add('hidden');
  }
}





