import { gsap } from "gsap";

export default class Menu {
    constructor() {
        this.header = document.getElementById('header');
        this.wrapper = document.getElementById('header-wrapper');
        this.btn = document.getElementById('menu-btn');
        this.menu = document.getElementById('menu');
        this.logo = document.getElementById('logo-svg');
        this.body = document.querySelector('body');
        this.main = document.querySelector('main');
        this.footer = document.querySelector('footer');

        this.btn.addEventListener('click', function(event) {

            if (this.btn.getAttribute('aria-expanded') == 'false') {
                this.open();
            } else {
                this.close();
            }
            event.preventDefault();
            event.stopImmediatePropagation();
            return false;
        }.bind(this));

        window.addEventListener('resize', function(event) {
            if (this.btn.getAttribute('aria-expanded') == 'true') {
                this.close();
            }
        }.bind(this));
    }

    open() {
        this.btn.setAttribute('aria-expanded', true);
        this.menu.classList.remove('hidden');
        this.wrapper.classList.remove('text-white');
        this.wrapper.classList.add('text-black');
        this.body.classList.add('overflow-hidden');
        this.main.classList.add('invisible');
        this.footer.classList.add('invisible');
    }

    close() {
        this.btn.setAttribute('aria-expanded', false);
        this.menu.classList.add('hidden');
        this.body.classList.remove('overflow-hidden');
        this.main.classList.remove('invisible');
        this.footer.classList.remove('invisible');

        if(this.header.classList.contains('header-negative')) {
          this.wrapper.classList.remove('text-black');
          this.wrapper.classList.add('text-white');
        }
    }

}
