import WaveSurfer from 'wavesurfer.js';

export default class Audio {

    constructor() {

        document.querySelectorAll('.audio-player').forEach(function(p, index) {
            console.log('audio loaded');
            var source = p.getAttribute('data-src'),
                isPlaying = false,
                thisParagraph = p.closest('.paragraph-audio'),
                playPauseBtn = thisParagraph.querySelector('.play'),
                durationEL = thisParagraph.closest('.paragraph-audio').querySelector('.paragraph-audio__duration'),
                iconPlay = thisParagraph.querySelector('svg .play'),
                iconPause = thisParagraph.querySelector('svg .pause');

            var wavesurfer = WaveSurfer.create({
                container: p,
                waveColor: '#6E88B6',
                progressColor: 'white',
                barWidth: 3,
                barHeight: 2,
                barRadius: 3,
                cursorWidth: 0,
                height: 25,
                responsive: true,
                barGap: null // the optional spacing between bars of the wave, if not provided will be calculated in legacy format
            });

            wavesurfer.load(source);

            wavesurfer.on('ready', function() {
                var duration = (wavesurfer.getDuration() / 60).toFixed(2);
                durationEL.textContent = duration.toString().replace('.', ':');
            });

            playPauseBtn.addEventListener('click', e => {
                if (!isPlaying) {
                    wavesurfer.play();
                    iconPlay.classList.add('hidden');
                    iconPause.classList.remove('hidden');
                    isPlaying = true;
                } else {
                    wavesurfer.pause();
                    iconPlay.classList.remove('hidden');
                    iconPause.classList.add('hidden');
                    isPlaying = false;
                }
            });


        })

    }

}