import { gsap, Expo } from 'gsap';

export default class WhereOnPage {

    constructor() {


      WhereOnPage.elem = document.getElementById('whereonpage');
      WhereOnPage.mission = document.getElementById('mission');
      WhereOnPage.missionH = WhereOnPage.mission.offsetHeight;
      WhereOnPage.articleH = document.querySelector('.article-content').offsetHeight;

      window.addEventListener('scroll', function() {

        if(this.scrollY > WhereOnPage.mission.offsetHeight) {
          WhereOnPage.show();
        } else {
          WhereOnPage.hide();
        }
        WhereOnPage.progressBar();

      }, false);

    }

    static show() {
      gsap.to(WhereOnPage.elem, {
          duration: .35,
          yPercent: 0,
          opacity: 1
      });
    }

    static hide() {
      gsap.to(WhereOnPage.elem, {
          duration: .35,
          yPercent: -100,
          opacity: 0
      });
    }

    static progressBar() {

        this.valueInPercent = Math.round(gsap.utils.mapRange(WhereOnPage.missionH, WhereOnPage.articleH + WhereOnPage.missionH - window.innerHeight, 0, 100, window.scrollY));
        this.valueInPercent = this.valueInPercent > 100 ? 100 :
        this.valueInPercent < 0 ? 0 : this.valueInPercent;
        this.valueInDeg = gsap.utils.mapRange(0, 100, 0, 360, this.valueInPercent);

        WhereOnPage.elem.querySelector('.percent__text').setAttribute('aria-label', 'Reading progress: '+this.valueInPercent+'%');
        WhereOnPage.elem.querySelector('.percent__text span').innerHTML = this.valueInPercent;
        WhereOnPage.elem.querySelector('.percent__bg').style.transition = 'all 2s ease-in-out'
        gsap.to('.percent__bg', { background: 'conic-gradient(black ' + this.valueInDeg + 'deg, rgba(255, 255, 255, 0) ' + this.valueInDeg + 'deg)', duration: .5, ease: Expo.easeOut });

    }

}
