import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import 'conic-gradient';
import { Modal } from "./modal.js";

/***
 * Alpine functions
 */

window.modal = Modal;

window.initiativesChart = function(originalData) {

  return {
    key: 'all',
    png: null,
    items: [],
    originalData: originalData,
    total: 0,

    init() {
      this.updateChart('all');
    },
    updateChart(key) {
      this.key = key;
      let items = [];
      let total = 0;
      this.png = null;

      let colors = {
        skills: '#B538D6',
        connectivity: '#D92832',
        data: '#2124F0',
        ecosystem: '#18B0C5',
        government: '#24617E',
        technology: '#4FBB71'
      }
      Object.keys(originalData[key]).forEach(function (tag) {
        total += originalData[key][tag];
        items.push({
          tag: tag,
          count: originalData[key][tag]
        })
      });
      let percent = 0;
      let chartStyle = 'border-radius: 50%;\n' +
        '            background: conic-gradient(';
      let stops = '';
      items.forEach(function (tag, index){
        percent +=  (tag.count / total)*100;
        stops += colors[tag.tag.toLowerCase()] +' 0 '+(percent)+'%';
        if (items.length > index+1 ) stops+= ', ';
      });
      chartStyle += stops+ ');';
      this.items = items;
      this.total = total;
      this.chartStyle = chartStyle;

      if (stops != ''){
        var conicGradient = new ConicGradient({
          stops:stops, // required
          //repeating: true, // Default: false
          size: 150 // Default: Math.max(innerWidth, innerHeight)
        });

        this.png = conicGradient.png;
      }



    }
  }
}

window.smoothScroll = function(target) {
  gsap.to(window, {duration: 2, scrollTo:target.srcElement.hash});
}


