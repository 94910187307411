import Cookies from 'js-cookie';

export function Modal() {

  const FOCUSABLE_ELEMENTS = [
    'a[href]',
    'area[href]',
    'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
    'select:not([disabled]):not([aria-hidden])',
    'textarea:not([disabled]):not([aria-hidden])',
    'button:not([disabled]):not([aria-hidden])',
    'iframe',
    'object',
    'embed',
    '[contenteditable]',
    '[tabindex]:not([tabindex^="-"])'
  ]

  function currentModalId() {
    var modalId = window.location.hash != '' ? window.location.hash.substr(1) : null,
    modalId = document.getElementById(modalId) ? modalId : null;
    return modalId;
  }

  if(currentModalId() != null) {
    document.body.style.overflowY = 'hidden';
  }

  return {
    modalId: currentModalId(),
		el: null,

    open(el, modalId, cookie = null) {
			
			this.el = el;
      this.cookie = cookie;

      // Cookie set to modal
      if (this.cookie) {
        if (Cookies.get(`cookie-${this.cookie.name}`) != true) {
          this.modalId = modalId;
          this.setFocusToFirstNode();
          document.body.style.overflowY = 'hidden';
          history.pushState({}, '', '#' + modalId);
        }
      }

      // No Cookie asked
      if (! this.cookie) {
        this.modalId = modalId;
        this.setFocusToFirstNode();
        document.body.style.overflowY = 'hidden';
        history.pushState({}, '', '#' + modalId);
      }
    },

    close() {
      this.modalId = null;
			if (this.el != null) this.el.focus();
      document.body.style.removeProperty('overflow-y');
      history.pushState({}, '', window.location.pathname + window.location.search);

      if (this.cookie) {
        if (Cookies.get(`cookie-${this.cookie.name}`) != true) {
          Cookies.set(`cookie-${this.cookie.name}`, 1, {expires: this.cookie.days} );
        }
      }
    },



    onKeydown (event) {
      if (event.keyCode === 27) this.close(event) // esc
      if (event.keyCode === 9) this.retainFocus(event) // tab
    },

    getFocusableNodes () {
      const nodes = document.getElementById(this.modalId).querySelectorAll(FOCUSABLE_ELEMENTS);
      return Array(...nodes);
    },

    /**
     * Tries to set focus on a node which is not a close trigger
     * if no other nodes exist then focuses on first close trigger
     */
    setFocusToFirstNode () {

      const focusableNodes = this.getFocusableNodes()

      // no focusable nodes
      if (focusableNodes.length === 0) return

      // remove nodes on whose click, the modal closes
      // could not think of a better name :(
      const nodesWhichAreNotCloseTargets = focusableNodes.filter(node => {
        //return !node.hasAttribute(this.config.closeTrigger)
        return true
      })

			setTimeout(function(){
				if (nodesWhichAreNotCloseTargets.length > 0) { nodesWhichAreNotCloseTargets[0].focus() }
	      if (nodesWhichAreNotCloseTargets.length === 0) { focusableNodes[0].focus() }
			}, 100);

    },

    retainFocus (event) {
      let focusableNodes = this.getFocusableNodes()

      // no focusable nodes
      if (focusableNodes.length === 0) return

      /**
      * Filters nodes which are hidden to prevent
      * focus leak outside modal
      */
      focusableNodes = focusableNodes.filter(node => {
       return (node.offsetParent !== null)
      })

      // if disableFocus is true
      if (!document.getElementById(this.modalId).contains(document.activeElement)) {
       focusableNodes[0].focus()
      } else {
       const focusedItemIndex = focusableNodes.indexOf(document.activeElement)

       if (event.shiftKey && focusedItemIndex === 0) {
         focusableNodes[focusableNodes.length - 1].focus()
         event.preventDefault()
       }

       if (!event.shiftKey && focusableNodes.length > 0 && focusedItemIndex === focusableNodes.length - 1) {
         focusableNodes[0].focus()
         event.preventDefault()
       }
      }
    },

  }

}
