import Highway from '@dogstudio/highway';
import Masonry from 'masonry-layout';
import {gsap} from "gsap";

export default class StoriesRenderer extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
  }

  onLeave() {
  }

  onEnterCompleted() {

    const msnryGrid = document.querySelector('.masonry');

    //get all element

    var toSort = document.querySelectorAll('.masonry-item');
    toSort = Array.prototype.slice.call(toSort, 0);

    toSort.sort(function (a, b) {
      var aord = (a.dataset.position > 0) ? a.dataset.position : 9999999;
      var bord = (b.dataset.position > 0) ? b.dataset.position : 9999999;
      return aord - bord;
    });

    var cards = [];
    for (var i = 0, l = toSort.length; i < l; i++) {
      if (!parseInt(toSort[i].dataset.position)){
        cards.push(toSort[i]);
      }
    }

    for (var i = 0, l = toSort.length; i < l; i++) {
      if (parseInt(toSort[i].dataset.position) > 0){
        cards.splice(parseInt(toSort[i].dataset.position -1), 0, toSort[i]);
      }
    }

    msnryGrid.innerHTML = "";

    for (var i = 0, l = cards.length; i < l; i++) {
      msnryGrid.appendChild(cards[i]);
    }


    if (msnryGrid) {
      this.msnry = new Masonry(document.querySelector('.masonry'), {
        itemSelector: '.masonry-item'
      });
    }


    if (window.location.search) {
      gsap.to(window, {duration: 2, scrollTo: '#form-stories'});
    }


  }

  onLeaveCompleted() {
    this.msnry.destroy();
  }
}
