import Cookies from 'js-cookie';

export default class CookieAgreed {
  constructor() {

    if (Cookies.get('cookie-agreed') != true){
      document.getElementById('cookie-warning').classList.remove('hidden');
    }else{
      this.consentGiven();
    }
    document.getElementById('consent-given-link').addEventListener('click', function(event){
      event.preventDefault();
      event.stopImmediatePropagation();
      this.consentGiven();
      return false;
    }.bind(this));
  }

  consentGiven(){
    Cookies.set('cookie-agreed', 1, {expires: 365} );
    document.getElementById('cookie-warning').classList.add('hidden');
    var event = new Event('consent-given');
    document.dispatchEvent(event);

  }

}
