import Highway from '@dogstudio/highway';
import { Swiper, Navigation, A11y } from "swiper";
Swiper.use([Navigation, A11y]);
import Masonry from 'masonry-layout';
import Video from '../../video';


export default class HomeRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {

        const carousel = document.querySelector('.swiper-container');
        if (carousel) {
            var mySwiper = new Swiper('.swiper-container', {
                slidesPerView: 1,
                spaceBetween: 30,
                watchSlidesVisibility: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2
                    },
                    1024: {
                        slidesPerView: 3
                    }
                }
            });
            //document.querySelector('.swiper-container').style.overflow = 'visible';
        }
    }
    onLeave() {}
    onEnterCompleted() {
        const msnryGrid = document.querySelector('.masonry');
        if (msnryGrid) {
            this.msnry = new Masonry(document.querySelector('.masonry'), {
                itemSelector: '.masonry-item',
                horizontalOrder: true
            });
        }
        new Video();

        if (document.getElementById('contact-us')){

          document.getElementById('edit-firstname').setAttribute('autocomplete','given-name');
          document.getElementById('edit-lastname').setAttribute('autocomplete','family-name');
          document.getElementById('edit-phone').setAttribute('autocomplete','tel');
          document.getElementById('edit-email').setAttribute('autocomplete','email');
          document.getElementById('edit-country-country').setAttribute('autocomplete','country-name');


          document.getElementById('contact-us').querySelector('.submit input').addEventListener('click',() => {
            document.getElementById('contact-us').querySelectorAll('input').forEach((field) => {
              field.setAttribute("aria-invalid", false);
            });
          });
          document.getElementById('contact-us').querySelectorAll('input').forEach((field) => {
            field.setAttribute("aria-invalid", false);
            field.addEventListener("invalid", () => {
              field.setAttribute("aria-invalid", true);
            });
          });

        }


        // window.demo1 = new Demo1();
        // window.demo2.start();
        // Demo3.start();

    }
    onLeaveCompleted() {
        //this.msnry.destroy();
        //   if (window.demo1) window.demo1.destroy();
        //   if (window.demo1) window.demo2.stop();
        //   Demo3.stop();

    }
}
