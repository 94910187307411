import { gsap } from 'gsap';

export default class Header {

    constructor() {

      // Vars
      Header.elem = document.getElementById('header');
      Header.wrapper = document.getElementById('header-wrapper');
      Header.lastScrollTop = 0;

      // Heights
      Header.elemH = Header.elem.offsetHeight;
      Header.wrapperH = Header.wrapper.offsetHeight;
      Header.govBarH = document.getElementById('govbar') ? document.getElementById('govbar').offsetHeight : 0;
      Header.alertH = document.getElementById('alert') ? document.getElementById('alert').offsetHeight : 0;
      Header.H = Header.elemH + Header.govBarH + Header.alertH;


      // Check scroll and fixe or not the header
      window.addEventListener('scroll', function() {

        if(this.scrollY > Header.H) {
          Header.setFixed();
        } else {
          Header.setAbsolute();
        }

        if(
          (this.scrollY > Header.H) &&
          (this.scrollY > Header.lastScrollTop)
        ) {
          Header.hide();
        }

        if(this.scrollY < Header.lastScrollTop) {
          Header.show();
        }

        Header.lastScrollTop = this.scrollY <= 0 ? 0 : this.scrollY;
      }, false);

    }

    static setFixed() {
      Header.elem.classList.add('fixed', 'header-positive');
      Header.elem.classList.remove('absolute', 'header-negative');
      Header.wrapper.classList.add('bg-white', 'text-black');
      Header.wrapper.classList.remove('bg-transparent', 'text-white');
      gsap.set(Header.elem, {y: -(Header.wrapperH)});
    }

    static setAbsolute() {
      Header.elem.classList.add('absolute', 'header-negative');
      Header.elem.classList.remove('fixed', 'header-positive');
      Header.wrapper.classList.add('bg-transparent', 'text-white');
      Header.wrapper.classList.remove('bg-white', 'text-black');
    }

    static show() {
      gsap.to(Header.elem, {
          duration: .35,
          y: 0
      });
      Header.elem.classList.add('header-visible');
      Header.elem.classList.remove('header-hidden');
    }

    static hide() {
      gsap.to(Header.elem, {
          duration: .35,
          y: -(Header.wrapperH)
      });
      Header.elem.classList.add('header-hidden');
      Header.elem.classList.remove('header-visible');
    }

}
