import Highway from '@dogstudio/highway';
import { Swiper, Navigation } from "swiper";
Swiper.use([Navigation]);

export default class AboutRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        const carousel = document.querySelector('.swiper-container');
        if (carousel) {
            var mySwiper = new Swiper('.swiper-container', {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    640: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 3
                    },
                    1024: {
                        slidesPerView: 4
                    }
                }
            });
            document.querySelector('.swiper-container').style.overflow = 'visible';
        }
    }
    onLeave() {}
    onEnterCompleted() {}
    onLeaveCompleted() {}
}