import Highway from '@dogstudio/highway';
import { gsap } from "gsap";

export default class Custom extends Highway.Transition {

    out({ from, trigger, done }) {

      // Scroll Top
      window.scrollTo(0, 0);

      // Show the loader
      document.getElementById('loader').classList.remove('hidden');

      // Create a clone of the mission
      Custom.oldMission = from.querySelector('#mission');
      Custom.oldMission.classList.remove('relative');
      Custom.oldMission.classList.add('mission-cloned', 'absolute', 'left-0', 'top-0', 'right-0', 'z-10');
      document.querySelector('main').appendChild(Custom.oldMission);

      // Remove actual content
      from.remove();

      // Hide the text
      gsap.to([
        Custom.oldMission.querySelector('#mission-text')
      ], {
        duration: .5,
        opacity: 0,
        y: -20
      });

      // Finish
      done();

    }

    in ({ from, to, trigger, done }) {

      // Hide the loader
      document.getElementById('loader').classList.add('hidden');

      // Store the missions variables
      Custom.oldMission = document.querySelector('.mission-cloned');
      Custom.newMission = to.querySelector('#mission');

      // Default state of the new mission
      gsap.set(Custom.newMission, {opacity:0}, 0);
      gsap.set(Custom.newMission.querySelector('#mission-text'), {opacity:0, y:20}, 0);


      // Start animation
      gsap.to([
        Custom.oldMission
      ], {

        // Make the clone as the same height of the new mission
        duration: .2,
        height: Custom.newMission.offsetHeight,
        onComplete: function() {

          // Show the new mission (behind the clone)
          gsap.set(Custom.newMission, {opacity:1}, 0);

          // Hide the old mission (delay to avoid the flick image)
          gsap.to([
            Custom.oldMission
          ], {
            duration: .5,
            opacity: 0,
            delay: .5,
            onComplete: function() {

              // Remove the old mission
              Custom.oldMission.remove();

              // Show the new text
              gsap.to([
                Custom.newMission.querySelector('#mission-text')
              ], {
                duration: .5,
                opacity: 1,
                y: 0,
                onComplete() {
                  done();
                }
              });

            }
          });

        }
      });

    }

}
