import { gsap } from "gsap";

export default class LockScroll{
  constructor(){
    this.body = document.getElementsByTagName('body')[0];
    this.is_lock = false;
  }

  lock(){
    this.is_lock = true;
    this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.body.style.position = "fixed";
    this.body.style.left = 0;
    this.body.style.right = 0;
    this.body.style.overflowY = "scroll";
  }

  unlock(scrollTop = null){
    if (!this.is_lock) return false;
    this.is_lock = false;
    this.body.style.position = "static";
    this.body.style.left = 'auto';
    this.body.style.right = 'auto';
    this.body.style.overflowY = "auto";
    if (scrollTop ==  null){
      scrollTop = this.scrollTop;
    }
    document.documentElement.scrollTop = document.body.scrollTop = scrollTop;

  }

}
