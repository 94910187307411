import Highway from '@dogstudio/highway';
import { Swiper, Navigation } from "swiper";
Swiper.use([Navigation]);
import { gsap } from "gsap";

export default class InitiativesRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        const carousel = document.querySelector('.swiper-container');
        if (carousel) {
            var mySwiper = new Swiper('.swiper-container', {
                slidesPerView: 'auto',
                spaceBetween: 0,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
            document.querySelector('.swiper-container').style.overflow = 'visible';
        }

        // Dots animation
        let dots = Array.from(document.querySelectorAll('.dot'));
        function shuffle(arra1) {
            var ctr = arra1.length,
                temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        }
        shuffle(dots);
        window.dotsAnimation = gsap.timeline({ repeat: -1, yoyo: true });
        window.dotsAnimation.to(dots, {
            opacity: 0.25,
            scale: 1,
            stagger: 0.1,
            duration: .8,
        });

    }
    onLeave() {}
    onEnterCompleted() {
      if(window.location.search){
        gsap.to(window, {duration: 2, scrollTo:'#form-initiatives'});
      }

      document.querySelector('.form-item-key input').setAttribute('title', 'Find initiative');

    }
    onLeaveCompleted() {}
}
